import {h} from "preact";
import {default as MarkdownItFactory, Options} from "markdown-it";
import Markup from "preact-markup";

const markdownItOptions: Options = {
    html: true,
    breaks: true,
    linkify: true,
}
const markdownIt = MarkdownItFactory(markdownItOptions)

const unescapedColonSpaceRegex = /((?<!(?<!\\)(?:\\\\)*\\)): (?!$)/

const unescapedInsertionPointRegex = /(?<!(?<!\\)(?:\\\\)*\\)\||$/
const emojisOrSpacesRegExp =
    /^<p>(\s|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\ufe0f)+<\/p>\n$/;

export interface ChatBubblesProps {
    text: string
    selfAuthor: string
}

const chatTitlePrefix = "# "
const chatSubtitlePrefix = "## "
const lineContinuationPrefix = ">> " as const
const blankContinuationPrefix = ">>" as const
function isContinuation(line: unknown): line is typeof blankContinuationPrefix|`${typeof lineContinuationPrefix}${string}` {
    return typeof line === "string" && (line.startsWith(lineContinuationPrefix) || line === blankContinuationPrefix)
}
const textboxPrefix = "["
const textboxSuffix = "]"
const datestampPrefix = "@ "

export function ChatBubbles({text, selfAuthor}: ChatBubblesProps) {
    const input: string[] = text.split("\n")
    const headerHtml: unknown[] = []
    const bodyHtml: unknown[] = []
    let lastAuthor = null
    let line = input.shift()
    while (typeof line === "string") {
        const lines: string[] = [line]
        // eslint-disable-next-line no-cond-assign
        line = input.shift()
        while (isContinuation(line)){
            lines.push(line.substring(lineContinuationPrefix.length))
            line = input.shift()
        }
        const result = lines.join("\n")
        if (result.trim() === "") {
            // skip it. who cares about blank lines honestly
        } else if (result.startsWith(chatTitlePrefix)) {
            if (bodyHtml.length === 0) {
                headerHtml.push(<p class="chatname">
                    <Markup type="html" trim={false} markup={markdownIt.renderInline(result.substring(chatTitlePrefix.length))} />
                </p>)
            } else {
                bodyHtml.push(<p class="syntaxError">
                    Title placed after body contents: <code>{result}</code>
                </p>)
            }
        } else if (result.startsWith(chatSubtitlePrefix)) {
            if (bodyHtml.length === 0) {
                headerHtml.push(<p class="subtitle">
                    <Markup type="html" trim={false} markup={markdownIt.renderInline(result.substring(chatTitlePrefix.length))} />
                </p>)
            } else {
                bodyHtml.push(<p class="syntaxError">
                    Subtitle placed after body contents: <code>{result}</code>
                </p>)
            }
        } else if (result.startsWith(textboxPrefix)) {
            const textboxContents = result.endsWith(textboxSuffix)
                ? result.substring(textboxPrefix.length, result.length - textboxSuffix.length)
                : result.substring(textboxPrefix.length)
            bodyHtml.push(<div class="fakeTextbox">
                <Markup type="html" trim={false} markup={markdownIt.render(textboxContents
                    .replace(unescapedInsertionPointRegex, `<span class="insertionPoint"></span>`))} />
            </div>)
        } else if (result.startsWith(datestampPrefix)) {
            bodyHtml.push(
                <p class="datestamp">
                    <Markup type="html" trim={false} markup={markdownIt.renderInline(result.substring(datestampPrefix.length))} />
                </p>)
        } else {
            const separator = unescapedColonSpaceRegex.exec(result)
            if (separator) {
                const messageAuthor = result.substring(0, separator.index)
                const messageContent = result.substring(separator.index + separator.length)
                const authorClass = messageAuthor === selfAuthor ? "self" : "other"
                if (messageAuthor !== lastAuthor) {
                    bodyHtml.push(<p class={`chatauthor ${authorClass}`}>
                        <Markup type="html" trim={false} markup={markdownIt.renderInline(messageAuthor)} />
                    </p>)
                    lastAuthor = messageAuthor
                }
                const rendered = markdownIt.render(messageContent)
                if (rendered === "<p>(image)</p>\n") {
                    bodyHtml.push(
                        <div class={`chatbubble loading ${authorClass}`} />)
                } else if (rendered === "<p>(...)</p>\n") {
                    bodyHtml.push(<div class={`chatbubble typing ${authorClass}`}>
                        <p>● ● ●</p>
                    </div>)
                } else if (rendered.match(emojisOrSpacesRegExp)) {
                    bodyHtml.push(<div class={`chatbubble emojionly ${authorClass}`}>
                        <Markup type="html" trim={false} markup={rendered} />
                    </div>)
                } else {
                    bodyHtml.push(<div class={`chatbubble ${authorClass}`}>
                        <Markup type="html" trim={false} markup={rendered} />
                    </div>)
                }
            } else {
                lastAuthor = null
                bodyHtml.push(<div class="system">
                    <Markup type="html" trim={false} markup={markdownIt.render(result)} />
                </div>)
            }
        }
    }
    return <blockquote class="chatbubbles">
        {headerHtml.length > 0 ? <div class="header">{headerHtml}</div> : null}
        {bodyHtml}
    </blockquote>
}